import "./Footer.css"

function Footer (){

    return(
    <div id="footer-container">
        <footer>
            <p>Rev. 1/2024 - Happy New Years!<i className="fas fa-glass-cheers" style={{color:"#eb6028"}}></i></p>
        </footer>
    </div>
    )
}

export default Footer